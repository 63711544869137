@media (max-width: 768px) {
  .table-header-cell {
    font-size: 0.6rem;
  }

  .prediction-table td {
    padding: 0;
  }
}

.prediction-table-container {
  width: 90%;
  margin: 40px auto;
  border-radius: 15px;
  background-color: #222;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.prediction-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1rem;
  color: #eee;
  table-layout: fixed;
}

.prediction-table th {
  background-color: #333;
  color: #fff;
  padding: 18px 20px;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  border-bottom: 1px solid #444;
  cursor: pointer;
}

.prediction-table tr {
  border-bottom: 1px solid #444;
  transition: background-color 0.2s;
}

.prediction-table tr:nth-child(even) {
  background-color: #383838;
}

.prediction-table tr:hover {
  background-color: #444;
  transition: background-color 0.2s;
}

.prediction-table td {
  padding: 5px 7spx;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.prediction-table td:nth-child(7) {
  color: #4caf50;
  font-weight: 600;
}

h1 {
  text-align: center;
  color: #fff;
  font-size: 2rem;
  margin-bottom: 30px;
  font-weight: 500;
}

.filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}

.filter-container label {
  color: #eee;
  margin-right: 5px;
}

.filter-container input,
.filter-container select {
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #333;
  color: #eee;
}

.filter-container input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.filter-container button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.filter-container button:hover {
  background-color: #45a049;
}
