.footer {
  background-color: "#1f1f1f";
  color: #8889;
  padding: "15px";
  font-size: "0.6rem";
  width: 100%;
}

.footer p {
  margin: 0;
  text-align: center;
}
