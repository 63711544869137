@media (max-width: 768px) {
  .vs-cell {
    display: none;
  }
  .competition-cell {
    display: none;
  }
  .team-cell {
    font-size: 0.6rem;
    font-weight: 400;
  }
  .prediction-cell {
    font-size: 0.6rem;
    font-weight: 400;
    padding: none;
    padding: 0;
  }
  .date-cell {
    font-size: 0.6rem;
    font-weight: 400;
    padding: 0;
  }
  .probability-cell {
    font-size: 0.6rem;
    font-weight: 400;
    padding: 0;
  }
}

.prediction-row {
  transition: background-color 0.2s ease, transform 0.2s ease;
}

.prediction-row:hover {
  background-color: #444;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.prediction-cell {
  color: white;
  font-weight: 500;
  padding: 12px 16px;
  text-align: center;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-cell {
  text-align: left;
}

.competition-cell {
  text-transform: uppercase;
  font-weight: 600;
}

.team-cell {
  font-weight: 600;
}

.vs-cell {
  font-style: italic;
  color: #aaa;
}

.winner-cell {
  font-weight: bold;
  transition: color 0.2s ease;
}

.probability-cell {
  color: #4caf50;
}
