body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #1e1e1e;
  font-family: Arial, sans-serif;
}

.login-container {
  width: 100%;
  min-width: 500px;
  padding: 40px;
  background-color: #333;
  border-radius: 12px;
  color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  box-sizing: border-box;
}

h2 {
  margin-bottom: 30px;
  font-size: 32px;
  color: #4caf50;
}

.input-group {
  margin-bottom: 30px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 12px;
  font-size: 18px;
  color: #ccc;
}

.input-group input {
  width: 100%;
  padding: 16px;
  border: 1px solid #444;
  border-radius: 8px;
  background-color: #222;
  color: white;
  font-size: 18px;
  transition: border-color 0.3s ease;
  box-sizing: border-box;
}

.input-group input:focus {
  border-color: #4caf50;
  outline: none;
}

button {
  width: 100%;
  padding: 18px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

button:hover {
  background-color: #45a049;
}

.error {
  color: #ff6b6b;
  margin-bottom: 25px;
  font-size: 18px;
}
